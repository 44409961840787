import request from '@/utils/request'


// 查询用户开锁记录列表
export function listLockRecord(query) {
  return request({
    url: '/user/user-lock-record/list',
    method: 'get',
    params: query
  })
}

// 查询用户开锁记录分页
export function pageLockRecord(query) {
  return request({
    url: '/user/user-lock-record/page',
    method: 'get',
    params: query
  })
}

// 查询用户开锁记录详细
export function getLockRecord(data) {
  return request({
    url: '/user/user-lock-record/detail',
    method: 'get',
    params: data
  })
}

// 新增用户开锁记录
export function addLockRecord(data) {
  return request({
    url: '/user/user-lock-record/add',
    method: 'post',
    data: data
  })
}

// 修改用户开锁记录
export function updateLockRecord(data) {
  return request({
    url: '/user/user-lock-record/edit',
    method: 'post',
    data: data
  })
}

// 删除用户开锁记录
export function delLockRecord(data) {
  return request({
    url: '/user/user-lock-record/delete',
    method: 'post',
    data: data
  })
}
